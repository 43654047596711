import styled, { css } from "styled-components";
import bkImage from "../../../../static/entete-image-offre.jpg";
import imgOverBk from "../../../../static/resolu-entete-offre.png";
import shareBKImage from "../../../../static/carte-vert.png";
export const $ImageHeaderSubPage = styled.header`
  padding: 0;

  .image-header {
    height: 360px;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 768px) {
      height: 175px;
    }

    .over-bk-image {
      background: url(${imgOverBk}) no-repeat;
      position: absolute;
      background-size: contain;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }
    .bk-image {
      background: url(${bkImage}) no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
`;
